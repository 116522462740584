import { Content } from "@/ui";
import { createTitle } from "@/functions/createTitle";
import { home_tiles_helper } from "@/helpers/home_tiles_helper";
import { specialisms_helper } from "@/helpers/specialisms_helper";
import Head from "next/head";

export default function Page({ content }) {
  return (
    <>
      <Head>
        <meta name="google-site-verification" content="iOeDif0BzlTlNFH_ZTsaov2ydNuvq6CtSHSNkPbaW8A" />
        <meta name="image" property="og:image" content="https://cdn.sourceflow.co.uk/y8e8x7v4q0zs7xoaz5agjbmpru1o" />
        <meta name="twitter:image" content="https://cdn.sourceflow.co.uk/y8e8x7v4q0zs7xoaz5agjbmpru1o" />
      </Head>
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({}) {
  return {
    props: {
      meta: {
        title: createTitle("Home"),
      },
      content: [
        {
          component: "HomeHero",
          skipFade: true,
          props: {
            title: {
              path: "page.home.component.Hero.title",
              placeholder: "Title",
            },
            title2: {
              path: "page.home.component.Hero.title2",
              placeholder: "Title",
            },
            boldTitle: {
              path: "page.home.component.Hero.boldTitle",
              placeholder: "Subtitle",
            },
            description: {
              path: "page.home.component.Hero.description",
              placeholder: "Personalised relationships, Sector specialisms, Global presence",
            },
            // video: "/homepage-videos/es_homepage_video.mp4",
            video: "/homepage-videos/es_homepage_video_compressed.mp4",
            className: "text-white",
          },
        },
        {
          component: "HomeTiles",
          props: {
            items: home_tiles_helper.fetch(),
          },
        },
        {
          component: "Testimonials",
          props: {},
        },
        {
          component: "Specialisms",
          props: {
            items: specialisms_helper.fetch({ onlyParent: true }),
          },
        },
        {
          component: "LatestBlogs",
          props: {
            title: "LATEST NEWS & INSIGHTS",
            newsTitle: { path: "page.home.latest-news.Header.title", placeholder: "WHAT'S NEW ON" },
          },
        },
      ],
    },
  };
}
